import React from 'react'
import 'animate.css'
import './index.scss'
import { Link } from 'react-router-dom'
import Card from 'react-bootstrap/Card'
import PrayingMantisPhoto from '../../assets/images/prayingmantis_screenshot.jpg'

const Projects = () => {
  return (
    <>
      <div className="animate__animated animate__backInRight">
        <ul className="nav">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/skills">Skills</Link>
          </li>
          <li className="disabledMenuOption">Projects</li>
          <li>
            <Link to="/contact">Contact</Link>
          </li>
        </ul>
      </div>

      <div className="center-page"></div>

      <div className="projectList">
        <div className="prayingMantisProject">
          <Card className="prayingMantisCard animate__animated animate__fadeIn">
            <Card.Img
              className="prayingMantisImage"
              variant="top"
              src={PrayingMantisPhoto}
              alt="Praying Mantis app"
            />
            <Card.Body>
              <Card.Title className="cardTitle">
                <a
                  href="https://github.com/DanielSp8/prayingmantis"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Check out on GitHub
                </a>
              </Card.Title>
              <Card.Text className="cardText">
                I created this app to help people in their prayer lives. It has
                the ability to create, save, update and delete prayer requests
                for different users. It also uses the Joshua Project API to
                display information, including a photo, for an unreached people
                group. <br />
                This mobile app also uses JWTs, JSON Web Tokens, to authenticate
                users. <br />
                Though I found building this app challenging at times, I also
                found it very rewarding.
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  )
}

export default Projects
