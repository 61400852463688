// import 'animate.css'
import './index.scss'
import { Link } from 'react-router-dom'

const Skills = () => {
  return (
    <>
      <div className="animate__animated animate__backInRight">
        <ul className="nav">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li className="disabledMenuOption">Skills</li>
          <li>
            <Link to="/projects">Projects</Link>
          </li>
          <li>
            <Link to="/contact">Contact</Link>
          </li>
        </ul>
      </div>

      <div className="skillsIntro animate__animated animate__fadeInUp">
        <div className="softSkills">
          <p className="subtitleInfo">Soft Skills</p>
          <p>
            Personally, I'm <span className="highlightedSkill">diligent</span>,
            an intentional and genuine{' '}
            <span className="highlightedSkill">listener</span>.
          </p>
          <p>
            I work well with a <span className="highlightedSkill">team</span>{' '}
            and I can also work-well in{' '}
            <span className="highlightedSkill">solitude</span>.
          </p>
          <p>
            I am <span className="highlightedSkill">consistent</span> and{' '}
            <span className="highlightedSkill">hard-working</span>.
          </p>
          <p>
            I'm also a quick <span className="highlightedSkill">learner</span>,
            and I am <span className="highlightedSkill">persistent</span> and{' '}
            <span className="highlightedSkill">resilient</span>, refusing to
            give up.
          </p>
        </div>
        <div className="hardSkills">
          <p className="subtitleInfo">Hard Skills</p>
          <p>
            I've spent some intentional time focused upon working with the{' '}
            <span className="highlightedSkill">MERN</span> stack.
          </p>
          <p>
            This stack includes{' '}
            <span className="highlightedSkill">MongoDB</span> for working with
            databases.
          </p>
          <p>
            It also includes <span className="highlightedSkill">Express</span>{' '}
            and <span className="highlightedSkill">NodeJS</span> for working on
            a server and the backend.
          </p>
          <p>
            And it uses <span className="highlightedSkill">React</span> as well
            as <span className="highlightedSkill">React-Native</span> for the
            development of the frontend.
          </p>
        </div>
      </div>
    </>
  )
}

export default Skills
