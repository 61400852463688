import 'animate.css'
import './index.scss'
import { Link } from 'react-router-dom'
import React, { useRef } from 'react'
import emailjs from '@emailjs/browser'

const Contact = () => {
  const form = useRef()

  const sendEmail = (e) => {
    e.preventDefault()

    emailjs
      .sendForm(
        'service_atrqdax',
        'template_dvhvpup',
        form.current,
        'zNApa1hXJuHRgCkp4'
      )
      .then(
        (result) => {
          alert(`Message successfully sent!  ${result.text}`)
          window.location.href = 'https://www.danielspeight.com'
        },
        (error) => {
          alert(`Failed to send message.  Please try again.  ${error.text}`)
        }
      )
  }

  return (
    <>
      <div className="animate__animated animate__backInRight">
        <ul className="nav">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/skills">Skills</Link>
          </li>
          <li>
            <Link to="/projects">Projects</Link>
          </li>
          <li className="disabledMenuOption">Contact</li>
        </ul>
      </div>

      <div className="center-page"></div>

      <div className="container contact-page">
        <div className="text-zone">
          <p>Feel free to write me a message! I would love to hear from you!</p>
        </div>
        <div className="contact-form">
          <form ref={form} onSubmit={sendEmail}>
            <ul>
              <li className="half">
                <input type="text" name="name" placeholder="Name" required />
              </li>
              <li className="half">
                <input type="email" name="email" placeholder="Email" required />
              </li>
              <li>
                <input
                  placeholder="Subject"
                  type="text"
                  name="subject"
                  required
                />
              </li>
              <li>
                <textarea
                  placeholder="Message"
                  name="message"
                  required
                ></textarea>
              </li>
              <li>
                <input type="submit" className="flat-button" value="SEND" />
              </li>
            </ul>
          </form>
        </div>
      </div>
    </>
  )
}

export default Contact
