import 'animate.css'
import './index.scss'
import ProfileImage from '../../assets/images/profilePhoto.png'
import { Link } from 'react-router-dom'

const Home = () => {
  return (
    <>
      <div className="personalPhoto">
        <img
          src={ProfileImage}
          alt="Profile of me"
          className="animate__animated animate__fadeIn"
        ></img>
        <div className="quotation animate__animated animate__backInLeft">
          <p className="quote">
            Hello! My name is{' '}
            <span className="highlightedItem">Daniel Speight</span>.
          </p>
          <p className="quote">
            I currently reside in{' '}
            <span className="highlightedItem">Grandview, Missouri</span>.
          </p>
          <p className="quote">
            I'm a full stack web developer, and I enjoy working on mobile apps.
          </p>
        </div>
      </div>

      <div className="animate__animated animate__backInRight">
        <ul className="nav">
          <li className="disabledMenuOption">Home</li>
          <li>
            <Link to="/skills">Skills</Link>
          </li>
          <li>
            <Link to="/projects">Projects</Link>
          </li>
          <li>
            <Link to="/contact">Contact</Link>
          </li>
        </ul>
      </div>
    </>
  )
}

export default Home
